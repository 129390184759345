import "./team.css";
import Team1 from "../../images/CEO.jpg";
import Team2 from "../../images/CTO.jpg";
import Team3 from "../../images/BD.jpeg";
import Team4 from "../../images/olga.jpeg";
import Team5 from "../../images/moyo.jpeg";

const team = () => {
  return (
    <section className="team">
      <h1>Meet the Team</h1>
      <div className="team-members">
        <div className="team-member">
          <img src={Team1} alt="CEO" className="team-photo" />
          <h2>Saint Anuma</h2>
          <h3>CEO</h3>
          <p>
            Saint is the visionary behind Angelo Consulting Group. With over 20
            years of experience in the tech industry, he leads the company with
            a commitment to innovation and excellence.
          </p>
        </div>

        <div className="team-member">
          <img src={Team2} alt="CTO" className="team-photo" />
          <h2>Obianuju Anuma</h2>
          <h3>CTO</h3>
          <p>
            Obianuju is the technical mastermind at Angelo Consulting Group. She
            drives the company’s technological advancements and ensures that we
            stay ahead of industry trends.
          </p>
        </div>

        <div className="team-member">
          <img src={Team4} alt="CTO" className="team-photo" />
          <h2>Olgah Mthombheni</h2>
          <h3>Head of Growth</h3>
          <p>
            Olgah is the growth leader at Angelo Group Technologies. She
            spearheads expansion efforts and ensures the company consistently
            reaches new markets and drives innovation.
          </p>
        </div>

        <div className="team-member">
          <img src={Team3} alt="CTO" className="team-photo sindi" />
          <h2>Sindisiwe Ncube</h2>
          <h3>Business Developer</h3>
          <p>
            Sindisiwe plays a vital role in expanding Angelo Consulting Group’s
            business horizons. Her keen market insights and
            relationship-building skills drive the company's growth initiatives.
          </p>
        </div>

        <div className="team-member">
          <img src={Team5} alt="CTO" className="team-photo" />
          <h2>Primrose Moyo</h2>
          <h3>Business Developer</h3>
          <p>
            Moyo excels at identifying new business opportunities and fostering
            client relationships. Her focus on innovation helps Angelo
            Consulting Group achieve its ambitious growth targets.
          </p>
        </div>
      </div>
    </section>
  );
};

export default team;
 